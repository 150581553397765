/* CustomerViewScreen CSS */

.CustomerViewScreen {
}

.CustomerViewScreen__titleBar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
}

.CustomerViewScreen__titleBar__right, 
.CustomerViewScreen__titleBar__left {
    align-items: center;
    display: flex;
}

.CustomerViewScreen__details {
    background-color: #F4F5F6;
    border-radius: 4px;
    column-gap: 20px;
    display: grid;
    grid-template-columns: calc(15% - 15px) calc(35% - 15px) calc(15% - 15px) calc(35% - 15px);
    margin-bottom: 45px;
    row-gap: 20px;
    padding: 30px;
}

.CustomerViewScreen__details__email {
    overflow: hidden;
    overflow-wrap: break-word;
}

@media screen and (max-width: 1000px) {

    .CustomerViewScreen__titleBar {
        display: block;
        margin-bottom: 30px;
    }

    .CustomerViewScreen__titleBar__left {
        margin-bottom: 10px;
    }

    .CustomerViewScreen__titleBar__right {
        margin: 0 0 0 auto;
        width: max-content;
    }

    .CustomerViewScreen__details {
        column-gap: 4%;
        grid-template-columns: 28% 68%;
        margin-bottom: 80px;
        row-gap: 20px;
        padding: 30px;
    }
}

@media screen and (max-width: 430px) {

    .CustomerViewScreen__details {
        padding: 20px 10px;
    }
}