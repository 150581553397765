/* ContractListScreen CSS */

.ContractListScreen {
}

.ContractListScreen__filters {
    align-items: center;
    column-gap: 20px;
    display: grid;
    grid-template-columns: 50% 20% 20% 10%;
    margin-bottom: 40px;
}

.ContractListScreen__filters__menuWrapper {
    align-items: center;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    display: flex;
    height: 61px;
    justify-content: space-between;
    padding: 15px;
    width: max-content;
}

.ContractListScreen__text {
    margin-bottom: 20px;
}

@media screen and (max-width: 1250px) {

    .ContractListScreen__filters {
        grid-template-columns: 50% 30% 20%;
    }
}

@media screen and (max-width: 900px) {

    .ContractListScreen__filters {
        grid-template-columns: auto 200px;
    }
}

@media screen and (max-width: 600px) {

    .ContractListScreen__filters {
        grid-template-columns: 100%;
        row-gap: 20px;
    }
}