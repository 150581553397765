/* ContactList CSS */

.ContactList {
    column-gap: 80px;
    display: grid;
    grid-template-columns: repeat(3, calc(33.3333% - 53.3333px));
}

@media only screen and (max-width: 2400px) {
    
    .ContactList {
        column-gap: 40px;
        grid-template-columns: repeat(2, calc(50% - 20px));
    }
}

@media only screen and (max-width: 1000px) {
    
    .ContactList {
        display: block;
    }
}

