/* ProcedureListItem CSS */

.UserListItem {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
}

.UserListItem:hover {
    background-color: #FBFDFE;
}                                                                                                                                                       
