/* ProcedureListItem CSS */

.ProcedureListItem {
    align-items: center;
    display: grid;
    grid-template-columns: 15% 60% 25%;
    padding: 10px 20px;
}

.ProcedureListItem:hover {
    background-color: #FBFDFE;
}

@media screen and (max-width: 550px) {

    .ProcedureListItem {
        grid-template-columns: 50% 50%;
    }
    
    .ProcedureListItem__name {
        text-align: right;
    }
}
