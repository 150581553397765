/* CustomerEquipmentList CSS */

.CustomerEquipmentList__header {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(5, 20%);
    padding: 10px 20px;
}

.CustomerEquipmentList__link {
    border-left: 1px solid #E1E1E1;
    border-right: 1px solid #E1E1E1;
    border-top: 1px solid #E1E1E1;
    display: block;
}

.CustomerEquipmentList__link:last-child {
    border-bottom: 1px solid #E1E1E1;
}

@media screen and (max-width: 900px) {

    .CustomerEquipmentList__header {
        display: none;
    }
}