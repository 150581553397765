/* UserListScreen CSS */

.UserListScreen {
}

.UserListScreen__filters {
    align-items: center;
    display: grid;
    grid-template-columns: 50% 20% 30%;
    margin-bottom: 40px;
}

.UserListScreen__text {
    margin-bottom: 20px;
}