/* ContractListItem CSS */

.ContractListItem {
    align-items: center;
    display: grid;
    grid-template-columns: 15% 57.5% 27.5%;
    padding: 10px 20px;
}

.ContractListItem:hover {
    background-color: #FBFDFE;
}

@media screen and (max-width: 1000px) {
    
    .ContractListItem {
        grid-template-columns: 100%;
        text-align: center;
    }
    
}
