/* TestResultsForm CSS */

.TestResultsForm__title {
    margin: 10px 0 30px 0;
}

.TestResultsForm__links {
    height: 30px;
    margin-bottom: 30px;
}

.TestResultsForm h2 {
    margin: 30px 0;
}

.TestResultsForm__column2 {
    margin-top: 199px;
}

.TestResultsForm__grid {
    display: grid;
    grid-template-columns: 60px 100px auto 150px;
    column-gap: 20px;
    margin-bottom: 20px;
    max-width: 600px;
    width: 100%;
}

.TestResultsForm__testLabel {
    align-items: center;
    display: flex;
}

.form__flexContainer__detail {
    margin-bottom: 30px;
    max-width: 600px;
    width: 100%;
}

.TestResultsForm__errorText {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.TestResultsForm__number {
    align-items: center;
    display: flex;
    margin-bottom: 0;
    width: 100%;
}

@media screen and (max-width: 1300px) {

    .TestResultsForm__links {
        height: auto;
        margin-bottom: 30px;
    }

    .TestResultsForm__column2 {
        margin-top: 0px;
    }
}

@media screen and (max-width: 900px) {

    .TestResultsForm__mobileGrid {
        display: grid;
        grid-template-columns: auto 80px;
    }
}

@media screen and (max-width: 700px) {

    .TestResultsForm__grid {
        display: block;
        max-width: 250px;
    }

    .TestResultsForm .form__flexContainer {
        display: block;
        max-width: 250px;
    }

    .TestResultsForm__testLabel {
        margin-bottom: 5px;
    }

    .TestResultsForm__number {
        width: 100%;
        margin-bottom: 15px;
    }

    .form__flexContainer__detail {
        max-width: 250px;
    }
}