/* TestResultsListItem CSS */

.TestResultsListItem {
    align-items: center;
    border-left: 1px solid #E1E1E1;
    border-right: 1px solid #E1E1E1;
    border-top: 1px solid #E1E1E1;
    display: grid;
    grid-template-areas: 'ref model status results button menu';
    grid-template-columns: repeat(3, 15%) 25% 15% 15%;
    padding: 10px 20px;
}

.TestResultsListItem:last-child {
    border-bottom: 1px solid #E1E1E1;
}

.TestResultsListItem__ref {
    grid-area: ref;
}

.TestResultsListItem__model {
    grid-area: model;
}

.TestResultsListItem__status {
    grid-area: status;
}

.TestResultsListItem__results {
    grid-area: results;
}

.TestResultsListItem__button {
    font-size: 15px;
    margin: 0 auto;
    grid-area: button;
}

.TestResultsListItem__menu {
    grid-area: menu;
}

.TestResultsListItem__mobileLabel {
    display: none;
}

@media screen and (max-width: 1550px) {

    .TestResultsListItem {
        grid-template-columns: repeat(3, 15%) 35% 17% 3%;
    }
}

@media screen and (max-width: 1200px) {
    
    .TestResultsListItem {
        grid-template-areas: 
            'ref model status'
            'results button menu';
        grid-template-columns: 50% 25% 25%;
        row-gap: 15px;
    }

    .TestResultsListItem__mobileLabel {
        display: inline-block;
    }

    .TestResultsListItem__model {
        text-align: center;
    }

    .TestResultsListItem__results div {
        text-align: left;
    }
}


@media screen and (max-width: 900px) {

    .TestResultsListItem {
        grid-template-areas:
            'ref status'
            'model status'
            'results results'
            'button button';
        grid-template-columns: 40% 60%;
        position: relative;
    }

    .TestResultsListItem__results {
        margin-bottom: 8px;
    }

    .TestResultsListItem__results div {
        text-align: center;
    }

    .TestResultsListItem__model {
        text-align: left;
    }

    .TestResultsListItem__status {
        margin: 0 0 0 auto;
    }

    .TestResultsListItem__button {
        margin: 0;
        text-align: left;
    }

    .TestResultsListItem__menu {
        position: absolute;
        bottom: 5px;
        right: 0;
    }
}

@media screen and (max-width: 420px) {

    .TestResultsListItem__results__left, 
    .TestResultsListItem__results__right {
        display: block;
    }
}

@media screen and (max-width: 370px) {

    .TestResultsListItem__status div{
        text-align: center;
        width: min-content;
    }
}
