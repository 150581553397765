.Header__top {
	align-items: center;
	background-color: #2D3543;
	display: flex;
	height: 35px;
	justify-content: space-between;
}

.header__top__right__logout {
	cursor: pointer;
	border-left: 1px solid #fff;
	margin-left: 8px;
	padding-left: 8px;
}

.Header {
	align-items: center;
	background-color: #40506B;
	border-bottom: 1px solid #ddd;
	display: flex;
	height: 100px;
	justify-content: space-between;
	margin-bottom: 40px;
	position: relative;
}

.Header__logo {
	width: 200px;
}

.Header__right {
	display: flex;
	align-items: center;
}

.Header__Search {
	margin-right: 30px;
}

.Header__Avatar {
	margin-left: 30px;
}

/*
* Hamburger
*/
.hamburger {
	background-color: transparent;
	border: 0;
	color: inherit;
	cursor: pointer;
	display: none;
	font: inherit;
    margin: 0 0 -5px auto;
	outline: none;
	overflow: visible;
	padding: 0;
	text-transform: none;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	z-index: 999999999;
}

.hamburger:hover, 
.hamburger.is-active:hover {
	opacity: 0.7;
	outline: none;
}

.hamburger.is-active-true .hamburger-inner,
.hamburger.is-active-true .hamburger-inner::before,
.hamburger.is-active-true .hamburger-inner::after {
	background-color: #fff;
}

.hamburger-box {
	width: 40px;
	height: 24px;
	display: inline-block;
	outline: none;
	position: relative;
}

.hamburger-inner {
	display: block;
	top: 50%;
	margin-top: -2px; 
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
	width: 40px;
	height: 2px;
	background-color: #fff;
	border-radius: 4px;
	position: absolute;
	transition-property: transform;
	transition-duration: 0.15s;
	transition-timing-function: ease; 
}

.hamburger-inner::before, .hamburger-inner::after{ 
	content: "";
	display: block;
}

.hamburger-inner::before {
	top: -10px; 
}

.hamburger-inner::after {
	bottom: -10px; 
}

/*
* Hamburger Spin
*/
.hamburger--spin .hamburger-inner {
	transition-duration: 0.22s;
	transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); 
}

.hamburger--spin .hamburger-inner::before {
	transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
	transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active-true .hamburger-inner {
	background-color: #1D1F23;
	transform: rotate(225deg);
	transition-delay: 0.12s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); 
}

.hamburger--spin.is-active-true .hamburger-inner::before {
	background-color: #1D1F23;
	top: 0;
	opacity: 0;
	transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active-true .hamburger-inner::after {
	background-color: #1D1F23;
	bottom: 0;
	transform: rotate(-90deg);
	transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}


@media only screen and (max-width: 1100px) {

	.Header__navigation {
		display: none;
	}

	.Header__Avatar {
		display: none;
	}

    .hamburger {
        display: block;
		margin-left: 20px;
    }
}


@media only screen and (max-width: 380px) {

	.Header__Search {
		margin-right: 15px;
	}
}