/* ManufacturerListItem CSS */

.ManufacturerListItem {
    align-items: center;
    display: grid;
    grid-template-columns: 100%;
    padding: 10px 20px;
}

.ManufacturerListItem:hover {
    background-color: #FBFDFE;
}                                                                                                                                                       
