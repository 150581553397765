/* SupplierListItem CSS */

.SupplierListItem {
    align-items: center;
    display: grid;
    grid-template-areas: 'name contact details';
    grid-template-columns: 55% 20% 25%;
    padding: 10px 20px;
}

.SupplierListItem:hover {
    background-color: #FBFDFE;
}

.SupplierListItem__email {
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media ( max-width: 1300px ) {
    
    .SupplierListItem {
        grid-template-areas:
        'name name'
        'contact details';
        grid-template-columns: 50% 50%;
        row-gap: 20px;
        text-align: center;
    }
    
}

@media ( max-width: 535px ) {
    
    .SupplierListItem {
        grid-template-areas:
        'name'
        'contact' 
        'details';
        grid-template-columns: 100%;
        row-gap: 20px;
    }
    
}

