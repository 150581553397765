/* ManufacturerListScreen CSS */

.ManufacturerListScreen {
}

.ManufacturerListScreen__filters {
    align-items: center;
    column-gap: 20px;
    display: grid;
    grid-template-columns: 50% 20% 30%;
    margin-bottom: 40px;
}

.ManufacturerListScreen__text {
    margin-bottom: 20px;
}

@media screen and (max-width: 1250px) {

    .ManufacturerListScreen__filters {
        grid-template-columns: 50% 30% 20%;
    }
}

@media screen and (max-width: 900px) {

    .ManufacturerListScreen__filters {
        grid-template-columns: auto 200px;
    }
}

@media screen and (max-width: 600px) {

    .ManufacturerListScreen__filters {
        grid-template-columns: 100%;
        row-gap: 20px;
    }
}