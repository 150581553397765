/* ProfileScreen CSS */

.ProfileScreen h2 {
    margin-bottom: 30px;
}

.ProfileScreen__details {
    background-color: #F4F5F6;
    border-radius: 4px;
    column-gap: 20px;
    display: grid;
    grid-template-columns: calc(15% - 15px) calc(35% - 15px) calc(15% - 15px) calc(35% - 15px);
    margin-bottom: 80px;
    row-gap: 20px;
    padding: 30px;
}


@media screen and (max-width: 1000px) {

    .ProfileScreen__details {
        column-gap: 4%;
        grid-template-columns: 46% 50%;
        margin-bottom: 80px;
        row-gap: 20px;
        padding: 30px;
    }
}