/* ContractJobList CSS */


.ContractJobList__tr {
    border-left: 1px solid #E1E1E1;
    border-right: 1px solid #E1E1E1;
    border-top: 1px solid #E1E1E1;
    display: block;
}

.ContractJobList__tr-th {
    display: grid;
    grid-template-columns: 15% 20% 20% 15% 15% 15%;
    padding: 10px 20px;
}

.ContractJobList__tr:last-child {
    border-bottom: 1px solid #E1E1E1;
}

@media screen and (max-width: 1000px) {
    
    .ContractJobList__tr.ContractJobList__tr-th {
        display: none;
    }
}