/* ContractViewScreen CSS */

.ContractViewScreen {
}

.ContractViewScreen__titleBar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
}

.ContractViewScreen__titleBar__right, 
.ContractViewScreen__titleBar__left {
    align-items: center;
    display: flex;
}

.ContractViewScreen__details {
    background-color: #F4F5F6;
    border-radius: 4px;
    column-gap: 2%;
    display: grid;
    grid-template-columns: 14% 34% 14% 34%;
    margin-bottom: 80px;
    row-gap: 20px;
    padding: 30px;
}

.ContractViewScreen__subtitle {
    margin-bottom: 30px;
}

.ContractViewScreen__table {
    margin-bottom: 60px;
}

.ContractViewScreen__tr {
    display: grid;
    grid-template-columns: 16% repeat(12, 7%);
}

.ContractViewScreen__tr__td {
    display: flex;
    justify-content: center;
}

.ContractViewScreen__scheduleString {
    display: none;
    margin-bottom: 60px;
}

.ContractViewScreen__list {
    margin-bottom: 60px;
}

.ContractViewScreen__button {
    margin: -60px 0 30px auto;
}

@media only screen and (max-width: 1000px) {

    .ContractViewScreen__titleBar {
        display: block;
        margin-bottom: 30px;
    }

    .ContractViewScreen__titleBar__left {
        margin-bottom: 10px;
    }

    .ContractViewScreen__titleBar__right {
        margin: 0 0 0 auto;
        width: max-content;
    }

    .ContractViewScreen__details {
        column-gap: 4%;
        grid-template-columns: 28% 68%;
        margin-bottom: 80px;
        row-gap: 20px;
        padding: 30px;
    }

    .ContractViewScreen__details__notes {
        line-height: 1.3;
    }
    
    .ContractViewScreen__table {
        display: none;
    }

    .ContractViewScreen__scheduleString {
        display: block;
    }
}

@media screen and (max-width: 430px) {

    .ContractViewScreen__details {
        padding: 20px 10px;
    }

    .ContractViewScreen__button {
        margin: 0px 0 30px auto;
    }
}