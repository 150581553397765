/* TestSpecificationListItem CSS */

.TestSpecificationListItem {
    align-items: center;
    display: grid;
    grid-template-columns: 60% 40%;
    padding: 10px 20px;
}

.TestSpecificationListItem:hover {
    background-color: #FBFDFE;
}

@media screen and (max-width: 1050px) {
    
    .TestSpecificationListItem {
        grid-template-columns: 80% 20%;
    }

    .TestSpecificationListItem__code {
        text-align: right;
    }
}
