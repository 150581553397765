/* BTDSelectField */


.BTDSelectField {
    align-items: center;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    font-family: inherit;
    font-size: inherit;
    line-height: 1.3;
    margin-bottom: 30px;
    min-height: 61px;
    outline: none;
    padding: 0 30px 0 20px;
    position: relative;
    width: 100%;
}

.BTDSelectField:focus {
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    outline: 0;
}

.BTDSelectField--height-slim {
    margin-bottom: 10px;
    min-height: 43px;
}

.BTDSelectField.medium {
    width: calc(50% - 10px);
}

.BTDSelectField.small {
    width: calc(25% - 20px);
}

.BTDSelectField__description {
}

.BTDSelectField__label {
    background-color: #fff;
    bottom: 5px;
    color: rgba(0,0,0,0.5);
    font-size: inherit;
    line-height: 1.25;
    position: absolute;
    left: 20px;
    top: 5px;
    transition: all 0.3s;
    z-index: -1;
}

.BTDSelectField__label__wrapper{
    align-items: center;
    display: flex;
    height: 100%;
    transition: all 0.3s;
}

.BTDSelectField__label.active {
    bottom: auto;
    font-size: 11px;
    height: auto;
    left: 15px;
    padding: 0 5px;
    top: -5px;
    z-index: 9;
}

.BTDSelectField__label.startIcon {
    left: 45px;
}

.BTDSelectField__label.startIcon.active {
    left: 15px;
}

.BTDSelectField__downArrow {
    background-image: url('./down-arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    height: 15px;
    position: absolute;
    right: 15px;
    top: 23px;
    transition: all 0.3s;
    width: 15px;
    z-index: -1;
}

.BTDSelectField--height-slim .BTDSelectField__downArrow {
    top: 13px;
}

.BTDSelectField__downArrow:-webkit-any(:checked), 
.BTDSelectField__downArrow:checked {
    transform: rotate(180deg);
}

.BTDSelectField__dropdown {
    background-color: #fff;
    border: 1px solid #E3E3E3;
    display: none;
    left: 0px;
    max-height: 200px;
    overflow: scroll;
    position: absolute;
    padding: 10px 0;
    top: 100%;
    width: 100%;
    z-index: 9999;
}

.BTDSelectField__dropdown.open {
    display: block;
}

.BTDSelectField__option {
    padding: 8px 20px;
}

.BTDSelectField__option:hover, 
.BTDSelectField__option.selected {
    background-color: #efefef;
    cursor: pointer;
}

.BTDSelectField__input--height-slim {
    min-height: 43px;
}

.BTDSelectField__input.startIcon {
    padding-left: 45px;
}

.BTDSelectField__startIcon {
    background-repeat: no-repeat;
    background-size: contain;
    height: 28px;
    left: 10px;
    opacity: 0.4;
    position: absolute;
    width: 28px;
}

.BTDSelectField__displayValue {
    line-height: 1.2;
}

.BTDSelectField__displayValue.startIcon {
    margin: 2px 0 0 25px;
}

.BTDSelectField__error {
    color: red;
    transform: translateY(-20px);
}
