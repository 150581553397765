/* LoginScreen CSS */


.LoginScreen {
    margin: 0 auto;
    max-width: 700px;    
}

.LoginScreen__header {
    align-items: center;
	background-color: #40506B;
	border-bottom: 1px solid #ddd;
	display: flex;
	justify-content: space-between;
    padding: 30px;
}

.LoginScreen__header__logo {
    height: auto;
    width: 150px;
}

.LoginScreen__main {
    background-color: #fff;
    padding: 40px;
}
