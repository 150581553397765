/* PurchaseOrderItemList CSS */

.PurchaseOrderItemList__header.tr {
    border-bottom: 1px solid #E1E1E1;
    padding: 0px 20px;
}

.PurchaseOrderItemList__tr {
    display: grid;
    grid-template-columns: 8% 52% 16% 16% 8%;
    padding: 0px 20px;
}

.PurchaseOrderItemList__link {
    border-left: 1px solid #E1E1E1;
    border-right: 1px solid #E1E1E1;
    border-top: 1px solid #E1E1E1;
    display: block;
}

.PurchaseOrderItemList__link:last-child {
    border-bottom: 2px solid #E1E1E1;
}

.PurchaseOrderItemList__border {
    border-bottom: 1px solid #E1E1E1;
}


@media screen and (max-width: 900px) {

    .PurchaseOrderItemList__header {
        display: none;
    }

    .PurchaseOrderItemList__tr {
        grid-template-columns: repeat(2, auto) 100px 100px 0;
    }
}