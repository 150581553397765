/* CustomerListItem CSS */

.CustomerEquipmentListItem {
    align-items: center;
    display: grid;
    grid-template-areas: 'ref serial man model type';
    grid-template-columns: repeat(5, 20%);
    padding: 10px 20px;
}

.CustomerEquipmentListItem__ref {
    grid-area: ref;
}

.CustomerEquipmentListItem__serial {
    grid-area: serial;
}

.CustomerEquipmentListItem__man {
    grid-area: man;
}

.CustomerEquipmentListItem__model {
    grid-area: model;
}

.CustomerEquipmentListItem__type {
    grid-area: type;
}

.CustomerEquipmentListItem:hover {
    background-color: #FBFDFE;
}

.CustomerEquipmentListItem__mobileLabel{
    display: none;
}

@media screen and (max-width: 900px) {

    .CustomerEquipmentListItem {
        grid-template-areas: 
            'ref serial'
            'man model'
            'type type';
        grid-template-columns: 50% 50%;
        row-gap: 15px;
        text-align: center;
    }

    .CustomerEquipmentListItem__mobileLabel{
        display: inline-block;
    }
    
}