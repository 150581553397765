.Navigation {
	display: flex;
	align-items: center;
}

.Navigation__list {
	display: flex;
	align-items: center;
}

.Navigation__list__item {
	margin: 0 20px;
}

.Navigation__list__item__link {
	border-radius: 4px;
	display: block;
	padding: 10px;
	transition: all 0.3s;
}

.Navigation__list__item__link:hover, 
.Navigation__list__item__link.current {
	background-color: #253551;
}
