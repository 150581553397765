/* BTDTab CSS */

.BTDTab {
    background-color: #6B6A6A;
    border-radius: 50rem;
    color: #fff;
    line-height: 1;
    padding: 7px 15px;
    width: max-content;
}

.BTDTab--colour-orange {
    background-color: #FF8000;
}

.BTDTab--colour-green {
    background-color: #59B200;
}

.BTDTab--colour-red {
    background-color: #FD1F1F;
}

.BTDTab--colour-blue {
    background-color: #0454A8;;
}

.BTDTab--colour-pink {
    background-color: #FF38F4;
}

.BTDTab--colour-yellow {
    background-color: #D87F2D;
}