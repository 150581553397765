/* ScreenTitle CSS */

.ScreenTitle {
	align-items: center;
	display:  flex;
	justify-content: space-between;
	margin-bottom: 60px;
}


.ScreenTitle__header__title {
	color: #40506B;
	font-size:  28px;
	font-weight:  500;
}

@media only screen and (max-width: 500px) {

	.ScreenTitle {
		display: block;
	}

	.ScreenTitle__header {
		margin-bottom:  20px;
	}

	.ScreenTitle__header__title {
		text-align: center;
	}
	

}
