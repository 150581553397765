/* PurchaseOrderList CSS */

.PurchaseOrderList .tr {
    display: grid;
    grid-template-columns: 12% 12% 52% 12% 12%;
    padding: 20px;
}

.PurchaseOrderList__link {
    border-left: 1px solid #E1E1E1;
    border-right: 1px solid #E1E1E1;
    border-top: 1px solid #E1E1E1;
    display: block;
}

.PurchaseOrderList__link:last-child {
    border-bottom: 1px solid #E1E1E1;
}

@media screen and (max-width:900px) {

    .PurchaseOrderList .PurchaseOrderList__th.tr {
        display: none;
    }
}