/* TestEquipmentListItem CSS */

.TestEquipmentListItem {
    align-items: center;
    display: grid;
    grid-template-columns: 30% 30% 40%;
    padding: 10px 20px;
}

.TestEquipmentListItem:hover {
    background-color: #FBFDFE;
}  

.TestEquipmentListItem__mobileLabel {
    display: none;
}

@media screen and (max-width: 850px) {

    .TestEquipmentListItem {
        grid-template-columns: 100%;
        row-gap: 10px;
        text-align: center;
    }

    .TestEquipmentListItem__mobileLabel {
        display: inline-block;
    }
}
