/* ContactsListItem CSS */

.ContactsListItem {
    align-items: center;
    display: grid;
    grid-template-columns: 45% 45% 10%;
    min-height: 88.5px;
    padding: 20px;
}

.ContactsListItem__email {
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


@media screen and (max-width: 480px) {

    .ContactsListItem {
        display: block;
        padding: 15px 10px;
        text-align: center;
    }

    .ContactsListItem__name {
        margin-bottom: 10px;
    }

    .ContactsListItem__menu {
        display: block;
        margin: 5px 0 0 auto;
        width: max-content;
    }
}
