/* CustomerStockPriceListItem CSS */

.CustomerStockPriceListItem {
    align-items: center;
    display: grid;
    grid-template-areas: 'left price menu';
    grid-template-columns: 85% 13% 2%;
    min-height: 99.5px;
    padding: 15px 20px;
    position: relative;
}

.CustomerStockPriceListItem__left {
    grid-area: left;
    padding-right: 15px;
}

.CustomerStockPriceListItem__left__ref {
    margin: 5px 0;
}

.CustomerStockPriceListItem__left__created {
    display: flex;
}

.CustomerStockPriceListItem__price {
    grid-area: price;
}

.CustomerStockPriceListItem__menu {
    grid-area: menu;
}

.CustomerStockPriceListItem a {
    display: inline-block;
    margin: 0 20px;
}

@media screen and (max-width: 950px) {

    .CustomerStockPriceListItem__left__created {
        display: block;
    }
}

@media screen and (max-width: 800px) {

    .CustomerStockPriceListItem {
        grid-template-columns: 83% 15% 2%;
    }
}

@media screen and (max-width: 600px) {

    .CustomerStockPriceListItem {
        grid-template-areas: 
            'left left'
            'price menu';
        grid-template-columns: 50% 50%;
    }

    .CustomerStockPriceListItem__left {
        margin-bottom: 10px;
        text-align: center;
    }

    .CustomerStockPriceListItem__left__ref span {
        display: block;
    }

    .CustomerStockPriceListItem__menu {
        margin: 0 0 0 auto;
        width: max-content;
    }
}
