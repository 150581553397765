/* PurchaseOrderItemListItem CSS */

.PurchaseOrderItemListItem {
    align-items: center;
    display: grid;
    grid-template-areas: 'qty desc unit total buttons';
    grid-template-columns: 8% 52% 16% 16% 8%;
    padding: 10px 20px;
}

.PurchaseOrderItemListItem:last-child {
    border-bottom: 2px solid #E1E1E1;
}

.PurchaseOrderItemListItem__buttons {
    display: flex;
    grid-area: buttons;
    justify-content: space-between;
    margin: 0 0 0 auto;
    max-width: 60px;
    width: 100%;
}

.PurchaseOrderItemListItem__qty {
    grid-area: qty;
}

.PurchaseOrderItemListItem__desc {
    grid-area: desc;
}

.PurchaseOrderItemListItem__unit {
    grid-area: unit;
    text-align: right;
}

.PurchaseOrderItemListItem__total {
    grid-area: total;
    text-align: right;
}

.PurchaseOrderItemListItem__mobileLabel {
    display: none;
    font-weight: 600;
}

@media screen and (max-width: 900px) {
    
    .PurchaseOrderItemListItem {
        grid-template-areas: 
            'qty buttons'
            'desc desc'
            'unit total';
        grid-template-columns: 50% 50%;
        row-gap: 5px;
    }

    .PurchaseOrderItemListItem__buttons {
        margin: 0 0 0 auto;
        width: max-content;
    }

    .PurchaseOrderItemListItem__buttons div {
        margin-left: 10px;
    }

    .PurchaseOrderItemListItem__desc {
        text-align: center;
    }

    .PurchaseOrderItemListItem__unit {
        text-align: left;
    }

    .PurchaseOrderItemListItem__total {
        text-align: right;
    }

    .PurchaseOrderItemListItem__mobileLabel {
        display: block;
    }
}