/* BTDCheckboxField */

 /* Customize the label (the container) */
.BTDRadioField {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    display: block;
    font-size: 22px;
    margin-bottom: 30px;
    position: relative;
    user-select: none;
}

.BTDRadioField__label {
    display: block;
    font-size: 16px;
    margin-bottom: 25px;
    padding: 5px 0 0 35px;
    position: relative;
}

/* Hide the browser's default radio button */
.BTDRadioField input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
}

  /* Create a custom radio button */
.BTDCheckboxField__button {
    background-color: #eee;
    border-radius: 50%;
    cursor: pointer;
    height: 25px;
    left: 0;
    position: absolute;
    top: 0;
    width: 25px;
}

/* On mouse-over, add a grey background color */
.BTDRadioField:hover input ~ .BTDCheckboxField__button {
    background-color: #ccc;
}

/* When the radio button is checked, add a background */
.BTDRadioField input:checked ~ .BTDCheckboxField__button.red {
    background-color: #BC3B3B;
}
.BTDRadioField input:checked ~ .BTDCheckboxField__button.green {
    background-color: #6DA726;
}
.BTDRadioField input:checked ~ .BTDCheckboxField__button.orange {
    background-color: #FE5100;
}
.BTDRadioField input:checked ~ .BTDCheckboxField__button.blue {
    background-color: #0454A8;
}
.BTDRadioField input:checked ~ .BTDCheckboxField__button.pink {
    background-color: #FF38F4;
}
.BTDRadioField input:checked ~ .BTDCheckboxField__button.yellow {
    background-color: #D87F2D;
}
.BTDRadioField input:checked ~ .BTDCheckboxField__button.black {
    background-color: #000;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.BTDCheckboxField__button:after {
content: "";
position: absolute;
display: none;
}

/* Show the indicator (dot/circle) when checked */
.BTDRadioField input:checked ~ .BTDCheckboxField__button:after {
display: block;
}

/* Style the indicator (dot/circle) */
.BTDRadioField .BTDCheckboxField__button:after {
top: 7px;
left: 7px;
width: 11px;
height: 11px;
border-radius: 50%;
background: white;
} 