/* BTDAutoCompleteField */

.BTDAutoCompleteField {
    font-size: inherit;
    margin-bottom: 30px;
    position: relative;
}

.BTDAutoCompleteField:focus {
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    outline: 0;
}

.BTDAutoCompleteField.medium {
    width: calc(50% - 10px);
}

.BTDAutoCompleteField.small {
    width: calc(25% - 20px);
}

.BTDAutoCompleteField__description {
}

.BTDAutoCompleteField__label {
    background-color: #fff;
    bottom: 5px;
    color: rgba(0,0,0,0.5);
    font-size: inherit;
    position: absolute;
    left: 20px;
    top: 5px;
    transition: all 0.3s;
}

.BTDAutoCompleteField__label__wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    transition: all 0.3s;
}

.BTDAutoCompleteField__label.active {
    bottom: auto;
    font-size: 11px;
    height: auto;
    left: 15px;
    padding: 0 5px;
    top: -5px;
    z-index: 9;
}

.BTDAutoCompleteField__selectInput {
    align-items: center;
    background-color: transparent;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    font-family: inherit;
    font-size: inherit;
    height: 63px;
    justify-content: space-between;
    padding-right: 15px;
    width: 100%;
}

.BTDAutoCompleteField__downArrow {
    background-image: url('./down-arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    height: 15px;
    transition: all 0.3s;
    width: 15px;
}

.BTDAutoCompleteField__downArrow.active {
    transform: rotate(180deg);
}

.BTDAutoCompleteField__selectInput__value {
    padding-left: 20px;
}

.BTDAutoCompleteField__dropdown {
    background-color: #fff;
    border: 1px solid #E3E3E3;
    overflow: visible;
    padding: 20px;
    position: absolute;
    width: 100%;
    z-index: 999;
}

.BTDAutoCompleteField__input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    display: flex;
    font-family: inherit;
    font-size: inherit;
    outline: none;
    padding: 18px 20px 18px 50px;
    width: 100%;
}

.BTDAutoCompleteField__input.noOptions {
    border: 1px solid #BC3B3B;
    color: #BC3B3B;
}

.BTDAutoCompleteField__options {
    background-color: #fff;
    border-radius: 4px;
    max-height: 150px;
    overflow: scroll;
    width: 100%;
    z-index: 9999;
}

.BTDAutoCompleteField__options__option {
    cursor: pointer;
    padding: 10px 20px;
}

.BTDAutoCompleteField__options__option:hover {
    background-color: #E3E3E3;
}

.BTDAutoCompleteField__error {
    color: red;
    transform: translateY(-20px);
}
