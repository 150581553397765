/* PurchaseOrderListItem CSS */

.PurchaseOrderListItem {
    align-items: center;
    display: grid;
    grid-template-areas: 'ref date name total status';
    grid-template-columns: 12% 12% 52% 12% 12%;
    padding: 10px 20px;
}

.PurchaseOrderListItem:hover {
    background-color: #FBFDFE;
}

@media screen and (max-width: 900px) {
    
    .PurchaseOrderListItem {
        grid-template-areas: 
            'ref date'
            'name name'
            'total status';
        grid-template-columns: 50% 50%;
        row-gap: 10px;
        text-align: center;
    }

    .PurchaseOrderListItem__ref, 
    .PurchaseOrderListItem__total {
        text-align: left;
    }

    .PurchaseOrderListItem__date {
        text-align: right;
    }

    .PurchaseOrderListItem__status {
        position: relative;
        width: 100%;
    }

    .PurchaseOrderListItem__status__tab {
        bottom: -10px;
        display: block;
        position: absolute;
        right: 0;
        width: max-content;
    }
}