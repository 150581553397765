/* NoteListItem CSS */

.NoteListItem__header {
    align-items: center;
    display: grid;
    grid-template-columns: 65% 35%;
    
    padding: 15px 20px;
    position: relative;
}

.NoteListItem__main {
    background-color: #F4F5F6;
    line-height: 1.35;
    padding: 15px;
}

.NoteListItem__header__left {
    line-height: 1.2;
    padding-right: 20px;
}

.NoteListItem__header__right {
    line-height: 1.2;
}

.NoteListItem__header__left__mobileText, 
.NoteListItem__header__right-mobile {
    display: none;
}

@media screen and (max-width: 1350px) {

    .NoteListItem__header {
        grid-template-columns: 55% 45%;
    }
}

@media screen and (max-width: 1000px) {

    .NoteListItem__header {
        grid-template-columns: 100%;
    }

    .NoteListItem__header__left__deskText, 
    .NoteListItem__header__right-desktop {
        display: none;
    }

    .NoteListItem__header__left__mobileText, 
    .NoteListItem__header__right-mobile {
        display: inline-block;
    }

    .NoteListItem__header__right {
        margin-top: 8px;
    }
}
