/* SupplierListScreen CSS */

.SupplierListScreen {
}

.SupplierListScreen__filters {
    align-items: center;
    display: grid;
    column-gap: 20px;
    grid-template-areas: 'search letter menu';
    grid-template-columns: 50% 20% 20% 10%;
    margin-bottom: 40px;
}

.SupplierListScreen__filters__menuWrapper {
    align-items: center;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    display: flex;
    height: 61px;
    justify-content: space-between;
    padding: 15px;
    width: max-content;
}

.SupplierListScreen__text {
    margin-bottom: 20px;
}

@media  (max-width: 1050px) {

    .SupplierListScreen__filters {
        grid-template-columns: auto 190px 62px;
    }
}

@media  (max-width: 650px) {

    .SupplierListScreen__filters {
        gap: 20px;
        grid-template-areas: 
        'search search'
        'letter menu';
        grid-template-columns: auto 62px;
    }
}