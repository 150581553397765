/* CustomerListItem CSS */

.CustomerListItem {
    align-items: center;
    display: grid;
    grid-template-areas: 'name contact details';
    grid-template-columns: 55% 20% 25%;
    padding: 10px 20px;
}

.CustomerListItem:hover {
    background-color: #FBFDFE;
}

.CustomerListItem__email {
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media screen and ( max-width: 1300px ) {
    
    .CustomerListItem {
        grid-template-areas:
        'name name'
        'contact details';
        grid-template-columns: 50% 50%;
        row-gap: 20px;
        text-align: center;
    }
    
}

@media screen and ( max-width: 535px ) {
    
    .CustomerListItem {
        grid-template-areas:
        'name'
        'contact' 
        'details';
        grid-template-columns: 100%;
        row-gap: 20px;
    }
    
}
