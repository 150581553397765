/* TestEquipment CSS */

.TestEquipmentList__header {
    align-items: center;
    display: grid;
    grid-template-columns: 30% 30% 40%;
    padding: 10px 20px;
}

.TestEquipmentList__link {
    border-left: 1px solid #E1E1E1;
    border-right: 1px solid #E1E1E1;
    border-top: 1px solid #E1E1E1;
    display: block;
}

.TestEquipmentList__link:last-child {
    border-bottom: 1px solid #E1E1E1;
}

@media screen and (max-width: 850px) {

    .TestEquipmentList__header {
        display: none;
    }
}