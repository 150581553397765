/* CustomerListItem CSS */

.ContractJobListItem {
    align-items: center;
    display: grid;
    grid-template-areas: 'date orderNum reportNum equipCount readingReg status';
    grid-template-columns: 15% 20% 20% 15% 15% 15%;
    padding: 10px 20px;
}

.ContractJobListItem:hover {
    background-color: #FBFDFE;
}

.ContractJobListItem__date {
    grid-area: date;
}

.ContractJobListItem__orderNum {
    grid-area: orderNum;
}

.ContractJobListItem__reportNum {
    grid-area: reportNum;
}

.ContractJobListItem__equipCount {
    grid-area: equipCount;
}

.ContractJobListItem__readingReg {
    grid-area: readingReg;
}

.ContractJobListItem__status {
    grid-area: status;
}

.ContractJobListItem__mobileLabel {
    display: none;
}

@media screen and (max-width: 1000px) {

    .ContractJobListItem {
        grid-template-areas: 
            'date status'
            'orderNum reportNum'
            'equipCount readingReg';
        grid-template-columns: 50% 50%;
        row-gap: 15px;
        text-align: center;
    }

    .ContractJobListItem__mobileLabel {
        display: block;
    }

    .ContractJobListItem__value {
        font-weight: 600;
    }

    .ContractJobListItem__date {
        font-weight: 600;
        text-align: left;
    }

    .ContractJobListItem__status {
        margin: 0 0 0 auto;
        width: max-content; 
    }
}

@media screen and (max-width: 370px) {

    .ContractJobListItem {
        padding: 15px 10px;
    }
}

@media screen and (max-width: 350px) {

    .ContractJobListItem {
        grid-template-areas: 'date' 'orderNum' 'reportNum' 'equipCount' 'readingReg' 'status';
        grid-template-columns: 100%;
    }

    .ContractJobListItem__date {
        text-align: center;
    }

    .ContractJobListItem__status {
        margin: 0 auto;
    }
}
