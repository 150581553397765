/* TestResultsList CSS */

.TestResultsList__header {
    align-items: center;
    border-left: 1px solid #E1E1E1;
    border-right: 1px solid #E1E1E1;
    border-top: 1px solid #E1E1E1;
    display: grid;
    grid-template-columns: repeat(3, 15%) 25% 15% 15%;
    padding: 10px 20px;
}

.TestResultsList .tr:last-child {
    border-bottom: 1px solid #E1E1E1;
}

@media screen and (max-width: 1200px) {

    .TestResultsList__header {
        display: none;
    }
}