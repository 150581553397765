/* TestSpecification CSS */

.TestSpecificationList__header {
    align-items: center;
    display: grid;
    grid-template-columns: 60% 40%;
    padding: 10px 20px;
}

.TestSpecificationList__link {
    border-left: 1px solid #E1E1E1;
    border-right: 1px solid #E1E1E1;
    border-top: 1px solid #E1E1E1;
    display: block;
}

.TestSpecificationList__link:last-child {
    border-bottom: 1px solid #E1E1E1;
}

@media screen and (max-width: 1050px) {
    
    .TestSpecificationList__header {
        grid-template-columns: 80% 20%;
    }

    .TestSpecificationList__header__code {
        text-align: right;
    }
}