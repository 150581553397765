/* ProcedureListScreen CSS */

.ProcedureListScreen {
}

.ProcedureListScreen__filters {
    align-items: center;
    display: grid;
    grid-template-columns: 50% 20% 30%;
    margin-bottom: 40px;
}

.ProcedureListScreen__text {
    margin-bottom: 20px;
}