/* ContractJobViewScreen CSS */

.ContractJobViewScreen {
}

.ContractJobViewScreen__titleBar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
}

.ContractJobViewScreen__titleBar__right, 
.ContractJobViewScreen__titleBar__left {
    align-items: center;
    display: flex;
}

.ContractJobViewScreen__titleBar__right__button {
    margin-left: 20px;
}

.ContractJobViewScreen__titleBar__right button:first-child {
    margin-left: 0;
}

.ContractJobViewScreen__details {
    background-color: #F4F5F6;
    border-radius: 4px;
    column-gap: 20px;
    display: grid;
    grid-template-columns: calc(15% - 15px) calc(35% - 15px) calc(15% - 15px) calc(35% - 15px);
    margin-bottom: 45px;
    row-gap: 20px;
    padding: 30px;
}

.ContractJobViewScreen__details a {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1000px) {
    
    .ContractJobViewScreen__titleBar {
        display: block;
    }

    .ContractJobViewScreen__titleBar__right {
        margin: 5px 0 0 auto;
        width: max-content;
    }

    .ContractJobViewScreen__details {
        column-gap: 4%;
        grid-template-columns: 28% 68%;
        margin-bottom: 80px;
        row-gap: 20px;
        padding: 30px;
    }
}
/* 
@media screen and (max-width: 120px) {

    .ContractJobViewScreen__titleBar__right {
        margin: 5px 0 0 0;
        display: block;
    }

    .ContractJobViewScreen__titleBar__right button {
        margin: 0 0 10px 0;
    }
} */


@media screen and (max-width: 430px) {

    .ContractJobViewScreen__details {
        padding: 20px 10px;
    }

    .ContractJobViewScreen__titleBar__right__button {
        margin: 0 0 0 10px;
    }

    .ContractJobViewScreen__titleBar__right__button:first-child {
        margin: 0;
    }
}


@media screen and (max-width: 400px) {

    .ContractJobViewScreen__details {
        padding: 20px 10px;
    }

    .ContractJobViewScreen__titleBar__right__button {
        padding: 15px 20px;
    }
}