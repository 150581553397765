/* UserList CSS */

.UserList__link {
    border-left: 1px solid #E1E1E1;
    border-right: 1px solid #E1E1E1;
    border-top: 1px solid #E1E1E1;
    display: block;
    max-width: 600px;
}

.UserList__link:last-child {
    border-bottom: 1px solid #E1E1E1;
}