/* StockListItem CSS */

.StockListItem {
    align-items: center;
    display: grid;
    grid-template-areas: 'name supplier quantity';
    grid-template-columns: 55% 30% 15%;
    padding: 10px 20px;
}

.StockListItem:hover {
    background-color: #FBFDFE;
}

.StockListItem__name {
    grid-area: name;
    padding-right: 15px;
}

.StockListItem__supplier {
    grid-area: supplier;
    padding-right: 15px;
}

.StockListItem__quantity {
    grid-area: quantity;
}

@media  (max-width: 1150px) {
    
    .StockListItem {
        grid-template-areas: 
            'name name'
            'supplier quantity';
        grid-template-columns: 50% 50%;
        row-gap: 20px;
        text-align: center;
    }
}

@media  (max-width: 385px) {
    
    .StockListItem {
        grid-template-areas: 
            'name'
            'supplier'
            'quantity';
        grid-template-columns: 100%;
    }
}
