/* CustomerEquipmentViewScreen CSS */

.CustomerEquipmentViewScreen {
}

.CustomerEquipmentViewScreen__titleBar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
}

.CustomerEquipmentViewScreen__titleBar__right, 
.CustomerEquipmentViewScreen__titleBar__left {
    align-items: center;
    display: flex;
}

.CustomerEquipmentViewScreen__details {
    background-color: #F4F5F6;
    border-radius: 4px;
    column-gap: 20px;
    display: grid;
    grid-template-columns: calc(15% - 15px) calc(35% - 15px) calc(15% - 15px) calc(35% - 15px);
    margin-bottom: 45px;
    row-gap: 20px;
    padding: 30px;
}

.CustomerEquipmentViewScreen__calibration {
    margin-bottom: 80px;
}

.CustomerEquipmentViewScreen__calibration h2 {
    margin-bottom: 30px;
}

.CustomerEquipmentViewScreen__calibration h4 {
    margin-bottom: 30px;
}

.CustomerEquipmentViewScreen__calibration__table {
    margin-bottom: 30px;
}

.CustomerEquipmentViewScreen__calibration .tr {
    display: grid;
    grid-template-columns: repeat(6, 15%);
    padding: 10px 20px;
}

@media screen and (max-width: 1250px) {

    .CustomerEquipmentViewScreen__calibration .tr {
        grid-template-columns: 15% repeat(5, 17%);
    }
}

@media screen and (max-width: 1100px) {

    .CustomerEquipmentViewScreen__calibration { 
        max-width: 320px;
        width: 100%;
    }

    .CustomerEquipmentViewScreen__calibration .tr {
        grid-template-columns: 150px auto ;
    }

    .CustomerEquipmentViewScreen__calibration .CustomerEquipmentViewScreen__calibration__table__gridRow {
        display: block;
    }

    .CustomerEquipmentViewScreen__calibration__table__gridRow__value {
        display: flex;
        justify-content: space-between;
        margin: 8px 0 0 50px;
        max-width: 180px;
        width: 100%;
    }
}

@media screen and (max-width: 1000px) {
    
    .CustomerEquipmentViewScreen__details {
        column-gap: 4%;
        grid-template-columns: 40% 60%;
        margin-bottom: 80px;
        row-gap: 20px;
        padding: 30px;
    }
}

@media screen and (max-width: 650px) {

    .CustomerEquipmentViewScreen__titleBar {
        display: block;
    }

    .CustomerEquipmentViewScreen__titleBar__right {
        margin: 5px 0 0 auto;
        width: max-content;
    }
}


@media screen and (max-width: 430px) {

    .CustomerEquipmentViewScreen__details {
        padding: 20px 10px;
    }
}