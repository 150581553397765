/* ContractScheduleScreen */

.ContractScheduleScreen__summary {
    display: grid;
    grid-template-columns: repeat(6, auto);
    gap: 4px;
}

.ContractScheduleScreen__summary__item {
    align-items: center;
    background-color: #EFEFEF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;
}

.ContractScheduleScreen__summary__item:hover {
    opacity: 0.75;
}

.ContractScheduleScreen__summary__item.current {
    color: #fff;
    background-color: #aaa;
}

.ContractScheduleScreen__months__month {
    padding: 80px 0;
}

.ContractScheduleScreen__months__month__title {
    margin-bottom: 15px;
}

.ContractScheduleScreen__months__month__subtitle {
    margin-bottom: 25px;
}

.ContractScheduleScreen__months__month__buttons {
    display: flex;
    margin: 0 0 15px auto;
    width: max-content;
}

.ContractScheduleScreen__months__month__tr {
    display: grid;
    grid-template-columns: 15% 55% 25% 5%;
}